<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container class="form-wrapper">
        <v-toolbar-title class="mb-6 sub-title"
          >Basic Information</v-toolbar-title
        >
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="First Name"
              placeholder="Enter First Name"
              v-model="data.first_name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Middle Name"
              placeholder="Enter Middle Name"
              v-model="data.middle_name"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Last Name"
              placeholder="Enter Last Name"
              v-model="data.last_name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Address"
              placeholder="Enter Address"
              v-model="data.address"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <!-- </v-row>
        <v-row> -->

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="City"
              placeholder="Enter City"
              v-model="data.city"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              label="Country"
              placeholder="Enter Country"
              v-model="data.country"
              :rules="[rules.required]"
              :items="countriesOption"
              item-text="name"
              item-value="iso3"
              @change="countryChanged"
              v-bind="basic"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="stateOptions"
              item-text="name"
              item-value="state_code"
              label="State"
              v-model="data.state"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Postal Code"
              placeholder="Enter Postal Code"
              v-model="data.postal_code"
              maxlength="4"
              :rules="[rules.required, rules.number, rules.postalLength]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <!-- </v-row>

        <v-row> -->
          <v-col class="d-flex" cols="12" sm="6" md="4">
            <v-select
              :items="companyOptions"
              item-text="company_name"
              item-value="id"
              label="Company"
              v-model="data.company_id"
              :rules="[rules.required]"
              v-bind="basic"
            >
              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn
                        block
                        v-on:click="showAddCompanyDialog()"
                        class="item-button"
                        >Add Company</v-btn
                      >
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Job Title"
              placeholder="Enter Job Title"
              v-model="data.job_title"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <!-- </v-row>

        <v-row> -->
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Email"
              placeholder="Enter Email"
              v-model="data.email"
              :rules="[rules.required, rules.email]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Phone Number"
              placeholder="Enter Number"
              v-model="data.number"
              :rules="[rules.required, rules.number]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6" md="4">
            <v-select
              :items="customerTypeOptions"
              item-text="name"
              item-value="id"
              label="Customer Type"
              v-model="data.customer_type_id"
              :rules="[rules.required]"
              v-bind="basic"
            >
              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn
                        block
                        v-on:click="showAddCustomerTypeDialog()"
                        class="item-button"
                        >Add Customer Type</v-btn
                      >
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <br />
        <v-toolbar-title class="mb-6 sub-title"
          >Contact Details</v-toolbar-title
        >
        <v-row v-for="(contact, index) in contactTypes" :key="index">
          <v-col class="d-flex" cols="12" sm="5" md="5">
            <v-select
              v-model="contact.contact_type_id"
              :items="contactTypeOptions"
              item-text="name"
              item-value="id"
              label="Contact Type"
              :name="`contactTypes[${index}][contact_type_id]`"
              :rules="[rules.required]"
              v-bind="basic"
            >
              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn
                        block
                        v-on:click="showAddContactTypeDialog()"
                        class="item-button"
                        >Add Contact Type</v-btn
                      >
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="contact.value"
              label="Value"
              placeholder="Enter Value"
              :name="`contactTypes[${index}][value]`"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1" md="1" v-if="index != 0">
            <v-icon v-on:click="deleteRow" class="delete-row-button"
              >mdi-delete</v-icon
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn class="add_new_item" v-on:click="addRow">
              Add New Item
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              color="white"
              elevation="2"
              large
              class="mb-2 submit-button"
              v-on:click="storeContact"
              block
              v-bind="basic"
              :loading="loading"
              >Add Contact</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="addCompany" max-width="600px">
      <addCompany @companyStored="companyStored" />
    </v-dialog>
    <v-dialog v-model="addContactType" max-width="600px">
      <addContactType @contactTypeStored="contactTypeStored" />
    </v-dialog>
    <v-dialog v-model="addCustomerType" max-width="600px">
      <addCustomerType @customerTypeStored="customerTypeStored" />
    </v-dialog>
  </div>
</template>

<script>
import util from "@/util/util";
import axios from "axios";
import addCompany from "@/components/Company/create";
import addContactType from "@/components/ContactType/create";
import addCustomerType from "@/components/CustomerType/create";
export default {
  components: { addCompany, addContactType, addCustomerType },
  data() {
    return {
      loading: false,
      data: {},
      rules: util.validation,
      companyOptions: [],
      stateOptions: util.stateOptions,
      customerTypeOptions: [],
      contactTypeOptions: [],
      countriesOption: [],
      contactTypes: [
        {
          contact_type_id: "",
          value: "",
        },
      ],
      basic: util.input.basic,
      addCompany: false,
      addContactType: false,
      addCustomerType: false,
    };
  },
  mounted() {
    this.fetchCompanies();
    this.fetchCustomerTypes();
    this.fetchContactTypes();
    this.fetchCountry();
  },
  methods: {
    countryChanged(d) {
      let selectedCountry = this.countriesOption.find((c) => {
        return c.iso3 == d;
      });
      selectedCountry
        ? (this.stateOptions = selectedCountry.states)
        : (this.stateOptions = []);
    },
    fetchCompanies() {
      util.http({ url: "company/get-all", method: "get" }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          this.companyOptions = res.data.data;
        }
      });
    },
    fetchCustomerTypes() {
      util.http({ url: "customer-type/get-all", method: "get" }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          this.customerTypeOptions = res.data.data;
        }
      });
    },
    fetchContactTypes() {
      util.http({ url: "contact-type/get-all", method: "get" }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          this.contactTypeOptions = res.data.data;
        }
      });
    },
    fetchCountry() {
      axios({
        url: "/static/country.json",
      })
        .then((resp) => {
          this.countriesOption = resp.data.data;
        })
        .catch();
    },
    storeContact() {
      if (this.$refs.form.validate()) {
        this.data.contactTypes = this.contactTypes;
        this.loading = true;
        util
          .http({
            url: "customer",
            method: "post",
            data: this.data,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Customer Stored");
              this.$emit("contactStored", true);
              this.$router.push({ name: "Contacts" });
              this.data = {};
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
      }
    },
    addRow() {
      this.contactTypes.push({
        contact_type_id: "",
        value: "",
      });
    },
    deleteRow: function(idx) {
      this.contactTypes.splice(idx, 1);
    },
    showAddCompanyDialog() {
      this.addCompany = true;
    },
    showAddContactTypeDialog() {
      this.addContactType = true;
    },
    showAddCustomerTypeDialog() {
      this.addCustomerType = true;
    },
    companyStored() {
      this.addCompany = false;
      this.fetchCompanies();
    },
    contactTypeStored() {
      this.addContactType = false;
      this.fetchContactTypes();
    },
    customerTypeStored() {
      this.addCustomerType = false;
      this.fetchCustomerTypes();
    },
  },
};
</script>
<style lang="scss">
.delete-row-button {
  padding-top: 8px;
}
.add_new_item {
  display: block !important;
  margin: 0 auto;
  background-color: #aaa !important;
}

.item-button {
  background: #1d2b58 !important;
  color: #fff !important;
}
</style>
